import { Container, Col, Row } from 'react-bootstrap';
import { BsFillEnvelopeFill, BsFillTelephoneFill, BsFillGeoAltFill } from 'react-icons/bs';

import { useTranslation } from "react-i18next";

import geoportalLogo from '../../assets/images/external/geoportal.png';
import dguLogo from '../../assets/images/external/dgu.png';
import logo from '../../assets/images/logo.png';
import landLogo from '../../assets/images/external/uredjena-zemlja.png';

const Footer = ({ addressLink, mail, phoneNumber }) => {

  const { t } = useTranslation();

  return (
    <footer className='gd-footer'>
      <Container style={{ margin: '60px' }} className='mx-auto'>
        <Row>
          <Col md={5}>
            <div className='d-flex align-items-center justify-content-center'>
              <a href="/#">
                <img alt='Logo' src={logo} width='350px'/>
              </a>
            </div>
            <hr className='gd-hr'/>
            <div className='d-flex align-items-center justify-content-center'>
              <a
                href={ addressLink }
                className='gd-link'>
                  <BsFillGeoAltFill className='gd-icon'/>
              </a>
              <a
                href={'mailto:' + mail}
                className='gd-link'>
                 <BsFillEnvelopeFill className='gd-icon'/>
              </a>
              <a
                href={'tel:' + phoneNumber }
                className='gd-link'>
                  <BsFillTelephoneFill className='gd-icon'/>
              </a>
            </div>
          </Col>
          <Col md={2} className='d-flex align-items-center justify-content-center gd-assoc'>
            <a href='https://geoportal.dgu.hr/' target='_blank' rel='noreferrer'>
                <img alt='Geoportal' src={geoportalLogo} width='180px'/>
            </a>
          </Col>
          <Col md={2} className='d-flex align-items-center justify-content-center gd-assoc'>
            <a href='https://dgu.gov.hr/' target='_blank' rel='noreferrer'>
                <img alt='DGU' src={dguLogo} width='150px'/>
            </a>
          </Col>
          <Col md={2} className='d-flex align-items-center justify-content-center gd-assoc'>
            <a href='https://oss.uredjenazemlja.hr/' target='_blank' rel='noreferrer'>
                <img alt='Uređena zemlja' src={landLogo} width='200px'/>
            </a>
          </Col>
        </Row>
      </Container>
      <hr/>
      <Container fluid className='text-center'>
        <p className='gd-copyright-text'>Copyright © 2022 Geodezija Dalmacija. { t('copyright') }</p>
      </Container>
    </footer>
  );
};

export default Footer;
