import { Container, Row } from "react-bootstrap";

import Service from "./Service";

import cadasterIcon from '../../assets/images/services/cadaster-projects.png';
import engineeringIcon from '../../assets/images/services/engineering-geodesy.png';
import legalisationIcon from '../../assets/images/services/object-legalisation.png';
import expertIcon from '../../assets/images/services/expert-witness.png';

import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  const services = [
    {
      icon: cadasterIcon,
      title: t('services.0.title'),
      descriptionItems: t('services.0.descriptionItems', { returnObjects: true })
    },
    {
      icon: engineeringIcon,
      title: t('services.1.title'),
      descriptionItems: t('services.1.descriptionItems', { returnObjects: true })
    },
    {
      icon: legalisationIcon,
      title: t('services.2.title'),
      descriptionItems: t('services.2.descriptionItems', { returnObjects: true })
    },
    {
      icon: expertIcon,
      title: t('services.3.title'),
      descriptionItems: t('services.3.descriptionItems', { returnObjects: true })
    }
  ];

  return (
      <Container fluid className='gd-services-wrapper' id='services'>
        <Container>
          <Row>
            {
              services.map(
                (service) =>
                  <Service
                    width={12 / services.length}
                    icon={service.icon}
                    title={service.title}
                    descriptionItems={service.descriptionItems}/>
              )
            }
          </Row>
        </Container>
      </Container>
  );
}

export default Services;