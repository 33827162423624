import { useState } from 'react'
import { Container, Button, Nav, Navbar } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

import 'bootstrap/dist/css/bootstrap.min.css';

import logoDark from '../../assets/images/logo.png';
import logoLight from '../../assets/images/logo-light.png';

import { useTranslation } from "react-i18next";

const Navigation = ({ phoneNumber }) => {
  const [navBarChange, setNavBarChange] = useState(false);
  const changeNavBar = () =>{
    if (window.scrollY >= 80){
      setNavBarChange(true);
    } else {
      setNavBarChange(false);
    }
  };
  window.addEventListener('scroll', changeNavBar);

  const scrollWithOffset = (e) => {
    const yCoordinate = e.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -130;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  const { t, i18n } = useTranslation();

  return (
    <Navbar scrolling dark expand='md' fixed='top' className={ navBarChange ? 'gd-nav gd-nav-white' : 'gd-nav gd-nav-transparent' }>
      <Container>
        <Navbar.Brand href='#home'>
          <img alt='Logo' src={ navBarChange ? logoDark : logoLight } width='200px'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav'/>
        <Navbar.Collapse>
          <Nav className='d-flex align-items-center justify-content-end' style={{ width: '100%' }}>
            <Link to='#about-us' className='gd-nav-link' scroll={e => scrollWithOffset(e)}>
              { t('navigation.about_us') }
            </Link>
            <Link to='#services' className='gd-nav-link' scroll={e => scrollWithOffset(e)}>
              { t('navigation.services') }
            </Link>
            <Link to='#contact' className='gd-nav-link' scroll={e => scrollWithOffset(e)}>
              { t('navigation.contact') }
            </Link>
            <Nav.Link className='gd-nav-link' href={ 'tel:' + phoneNumber }>
              <Button className='gd-btn'>{ phoneNumber }</Button>
            </Nav.Link>
            <Nav.Item className='gd-nav-lang-switcher d-flex align-items-center'>
              <Nav.Link className={ i18n.language === 'hr' ? 'gd-nav-lang selected' : 'gd-nav-lang' } onClick={ () => { i18n.changeLanguage( 'hr' ) } }>HR</Nav.Link>
              |
              <Nav.Link className={ i18n.language === 'en' ? 'gd-nav-lang selected' : 'gd-nav-lang' } onClick={ () => { i18n.changeLanguage( 'en' ) } }>EN</Nav.Link>
              |
              <Nav.Link className={ i18n.language === 'de' ? 'gd-nav-lang selected' : 'gd-nav-lang' } onClick={ () => { i18n.changeLanguage( 'de' ) } }>DE</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;