
import React, { useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { HashLink as Link } from 'react-router-hash-link';

const ScrollButton = () =>{
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 220) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Link className='rounded-circle gd-nav-scroll-to-top' style={{ display: visible ? 'inline' : 'none' }} >
     <BsChevronUp className='gd-nav-scroll-to-top-icon' color='#fff' size='30px' onClick={ scrollToTop }/>
    </Link>
  );
}

export default ScrollButton;