import { Container, Col, Row } from 'react-bootstrap';

import { useTranslation, Trans } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Container style={{ margin: '60px' }} className='mx-auto' id='about-us'>
       <Row>
        <Col md={5} className='d-flex align-items-center'>
          <p className='gd-about'>
            <Trans i18nKey='about.text' components={[<span />]}/>
          </p>
        </Col>
        <Col md={1}></Col>
        <Col md={6} className='gd-motto-container'>
          <p>{ t('about.motto.0') }</p>
          <p>{ t('about.motto.1') }</p>
          <p>{ t('about.motto.2') }</p>
        </Col>
       </Row>
    </Container>
  );
}

export default AboutUs;