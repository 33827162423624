import AboutUs from './components/about-us/AboutUs';
import Footer from './components/footer/Footer';
import Navigation from './components/navigation/Navigation';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';
import Map from './components/map/Map';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';

import backgroundImage from './assets/images/background.jpg';

import './App.css';
import { BrowserRouter } from 'react-router-dom';

import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Navigation phoneNumber='+385989116842' />
      <ScrollToTop />

      <div style={{
        backgroundImage: 'url(' + backgroundImage + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        boxShadow: 'inset 0 0 0 2000px #0000009A',
        width: '100%',
        height: '100vh',
        position:'relative'
      }}>
        <div>
          <p className='gd-cover-title'>
            GEODEZIJA DALMACIJA D.O.O.
          </p>
          <hr className='gd-cover-hr'/>
          <p className='gd-cover-subtitle'>
            { t('coverSubtitle') }
          </p>
        </div>
      </div>

      <AboutUs/>
      <Services/>
      <Contact
        street='Mažuranićevo šetalište 24b'
        city='21000, Split'
        locationDescription={ t('locationDescription') }
        addressLink='https://goo.gl/maps/Dq6aATaRZQRwn3V96'
        mail='geo@geodezija-dalmacija.hr'
        phoneNumber='+385989116842'/>
      <Map/>
      <Footer
        address='Mažuranićevo šetalište 24b, 21000, Split (zgrada FINA-e)'
        addressLink='https://goo.gl/maps/Dq6aATaRZQRwn3V96'
        mail='geo@geodezija-dalmacija.hr'
        phoneNumber='+385989116842'/>
    </BrowserRouter>
  );
}

export default App;
