import { Col } from "react-bootstrap";

const Service = ({ width, icon, title, descriptionItems }) => {
  return (
      <Col md={ width } className='gd-service'>
        <div className='gd-service-img-wrapper'>
          <img src={ icon } alt={ title }/>
        </div>
        <h3>{ title }</h3>
        <ul>
          {
            descriptionItems.map( (item) => <li>{ item }</li> )
          }
        </ul>
      </Col>
  );
}

export default Service;