import { Container, Col, Button, Form, Row } from 'react-bootstrap';
import { BsFillEnvelopeFill, BsFillTelephoneFill, BsFillGeoAltFill } from 'react-icons/bs';
import { useState } from 'react';
import axios from 'axios';

import { useTranslation } from "react-i18next";

const Contact = ({ street, city, locationDescription, addressLink, mail, phoneNumber }) => {
  const { t } = useTranslation();

  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});

  const handleChange = (e, field) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: "POST",
      url: 'https://geodezija-dalmacija.hr/contact.php',
      headers: { "content-type": "multipart/form-data" },
      data: formData
    })
    .then(() => {
      setMailSent(true);
    })
    .catch(error => {
      setError( error.message );
      console.log( error.message );
    });
    e.target.parentElement.reset();
  };

  return (
    <Container style={{ margin: '60px' }} className='mx-auto gd-contact' id='contact'>
      <Row>
         <Col md={6}>
            <div className='gd-title'>
              <p>geodezija</p>
              <p>dalmacija</p>
              <p>d.o.o.</p>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '40px 10fr' }} className='gd-contact-details'>
              <a href={ addressLink } style={{ display: 'contents' }} className='gd-link'>
                <div><BsFillGeoAltFill className='gd-icon'/></div>
                <div>
                  <p>{ street }</p>
                  <p>{ city }</p>
                  <p>{ '(' + locationDescription + ')' }</p>
                </div>
              </a>
              <a href={'mailto:' + mail} style={{ display: 'contents' }} className='gd-link'>
                <div><BsFillEnvelopeFill className='gd-icon'/></div>
                <div>{ mail }</div>
              </a>
              <a href={'tel:' + phoneNumber } style={{ display: 'contents' }} className='gd-link'>
                <div><BsFillTelephoneFill className='gd-icon'/></div>
                <div>{ phoneNumber }</div>
              </a>
            </div>
        </Col>
        <Col md={6}>
            <h3>{ t('contactForm.title') }</h3>
            <Form>
              <Form.Group controlId="formFullName">
                <Form.Control className="gd-input" type="text" placeholder={ t('contactForm.namePlaceholder') } onChange={e => handleChange(e, 'name')} />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Control className="gd-input" type="email" placeholder={ t('contactForm.emailPlaceholder') } onChange={e => handleChange(e, 'email')} />
              </Form.Group>
              <Form.Group controlId="formContact">
                <Form.Control className="gd-input" type="tel" placeholder={ t('contactForm.numberPlaceholder') } onChange={e => handleChange(e, 'contact')} />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Control className="gd-input" as="textarea" rows={5}  col={5} placeholder={ t('contactForm.msgPlaceholder') } onChange={e => handleChange(e, 'message')} />
              </Form.Group>
              <Button className='gd-btn' variant="primary" type="submit" onClick={e => handleFormSubmit(e)} >
                { t('contactForm.sendButton') }
              </Button>
            </Form>
            <div>
              { mailSent && <div className="alert alert-success" style={{ borderRadius: '2px', marginTop: '20px' }}>{ t('contactForm.success') }</div> }
              { error && <div className="alert alert-warning" style={{ borderRadius: '2px', marginTop: '20px' }}>{ t('contactForm.failure') }</div> }
            </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;