import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Container } from 'react-bootstrap';
import { BsFillGeoAltFill } from 'react-icons/bs';

import * as ReactDOMServer from 'react-dom/server';
import * as L from 'leaflet';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css'

const icon = L.divIcon({
  className: 'gd-map-icon',
  iconAnchor: [ 30, 60 ],
  html: ReactDOMServer.renderToString(<BsFillGeoAltFill />)
});

const Map = () => {
  return (
    <Container fluid style={{ padding: '0' }}>
      <MapContainer
        center={[ 43.51316, 16.4519 ]}
        zoom={16}
        scrollWheelZoom={false}
        style={{ height: '400px', backgroundColor: 'white', marginTop: '80px', marginBottom: '90px', zIndex: '2' }}>
        <TileLayer
          url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}@2x.png?api_key=bce66b98-cccd-45f8-a5df-a72f815dadac'
        />
        <Marker position={[ 43.51296, 16.4510 ]} icon={ icon }>
          <Popup>
            Geodezija Dalmacija d.o.o.
          </Popup>
        </Marker>
      </MapContainer>
    </Container>
  );
};

export default Map;