import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';

const resources = {
  hr: {
    translation: {
      navigation: {
        about_us: 'O nama',
        services: 'Usluge',
        contact: 'Kontakt'
      },

      about: {
        text:
          `<0>Geodezija Dalmacija d.o.o.</0> je tvrtka za obavljanje geodetske djelatnosti sa sjedištem u Splitu.
          Tvrtka je specijalizirana za obavljanje stručnih geodetskih poslova za potrebe katastra nekretnina, prostornog uređenja, projektiranja,
          gradnje te upravnih i sudskih postupaka koje obavljamo <0>na području cijele Dalmacije.</0> Posjedujemo sav potreban instrumentarij
          i opremu kao i potrebnu suglasnost Državne geodetske uprave za obavljanje geodetske djelatnosti.`,
        motto: [
          'Profesionalan pristup',
          'Kvalitetna usluga',
          'Brza izrada',
        ],
      },

      services: [
        {
          title: 'Katastar',
          descriptionItems: [
            'parcelacija zemljišta',
            'uplana objekata',
            'usklađenje katastra i zemljišne knjige',
            'provedba akata prostornog uređenja'
          ]
        },
        {
          title: 'Inženjerska geodezija',
          descriptionItems: [
            'geodetska podloga za projektiranje',
            'iskolčenje građevina',
            'nadzor gradnje'
          ]
        },
        {
          title: 'Legalizacija',
          descriptionItems: [
            'geodetski snimak izvedenog stanja'
          ]
        },
        {
          title: 'Sudska vještva',
          descriptionItems: [
            'geodetska izmjera',
            'izrada nalaza i mišljenja'
          ]
        }
      ],

      contactForm: {
        title: 'Pošaljite upit',
        namePlaceholder: 'Vaše ime i prezime',
        emailPlaceholder: 'Vaš email',
        numberPlaceholder: 'Vaš kontakt broj',
        msgPlaceholder: 'Vaša poruka',
        sendButton: 'Pošalji',
        success: 'Poruka uspješno poslana!',
        failure: 'Nažalost, došlo je do problema pri slanju poruke!'
      },

      coverSubtitle: 'geodetske usluge i vještačenje',
      locationDescription: 'zgrada FINA-e',

      copyright: 'Sva prava pridržana.'
    },
  },
  en: {
    translation: {
      navigation: {
        about_us: 'About us',
        services: 'Services',
        contact: 'Contact'
      },

      about: {
        text:
        `<0>Geodezija Dalmacija d.o.o.</0> is a company for performing geodetic activities with headquarters in Split.
        The company is specialized in performing professional geodetic work for the needs of the real estate cadastre, spatial planning, design,
        construction and administrative and court procedures that we carry out <0>in the whole area of Dalmatia.</0> We have all the necessary instruments
        and equipment as well as the necessary consent of the State Geodetic Administration for performing geodetic activities.`,
        motto: [
          'Professional approach',
          'Quality service',
          'Quick delivery',
        ],
      },

      services: [
        {
          title: 'cadastre',
          descriptionItems: [
            'land parcelling',
            'planning of facilities',
            'coordination of cadastre and land register',
            'implementation of spatial planning acts'
          ]
        },
        {
          title: 'Engineering Geodesy',
          descriptionItems: [
            'geodetic basis for designing',
            'staking out buildings',
            'construction supervision'
          ]
        },
        {
          title: 'Legalization',
          descriptionItems: [
            'geodetic survey of the derived state'
          ]
        },
        {
          title: 'Court expert',
          descriptionItems: [
            'geodetic survey',
            'making findings and opinions'
          ]
        }
      ],

      contactForm: {
        title: 'Send inquiry',
        namePlaceholder: 'Your name',
        emailPlaceholder: 'Your email',
        numberPlaceholder: 'Your phone number',
        msgPlaceholder: 'Your message',
        sendButton: 'Send',
        success: 'Message successfully sent!',
        failure: 'Unfortunately, there was a problem sending the message!'
      },

      coverSubtitle: 'geodetic services and court expertise',
      locationDescription: 'FINA building',

      copyright: 'All rights reserved.'
    },
  },
  de: {
    translation: {
      navigation: {
        about_us: 'Über uns',
        services: 'Dienstleistungen',
        contact: 'Kontakt'
      },

      about: {
        text:
        `<0>Geodezija Dalmacija d.o.o.</0> ist ein Unternehmen um geodätische Aktivitäten mit Hauptsitz in Split durchzuführen.
        Das Unternehmen ist spezialisiert auf die Durchführung professioneller geodätischer Arbeiten für die Belange des Liegenschaftskatasters, der Raumplanung, des Entwurfs,
        Bau- und Verwaltungs- und Gerichtsverfahren, die wir <0>in ganz Dalmatien durchführen.</0> Wir verfügen über alle notwendigen Instrumente
        und Ausrüstung sowie die erforderliche Zustimmung der Staatlichen Geodätischen Verwaltung zur Durchführung geodätischer Tätigkeiten.`,
        motto: [
          'Professioneller Ansatz',
          'Qualitätsdienstleistung',
          'Schnelle Lieferung',
        ],
      },

      services: [
        {
          title: 'Kataster',
          descriptionItems: [
            'Parzellierung',
            'Anlagenplanung',
            'Koordination von Kataster und Grundbuch',
            'Durchführung von Raumordnungsgesetzen'
          ]
        },
        {
          title: 'Ingenieurgeodäsie',
          descriptionItems: [
            'Geodätische Grundlagen für das Entwerfen',
            'Gebäude abstecken',
            'Bauaufsicht'
          ]
        },
        {
          title: 'Legalisierung',
          descriptionItems: [
            'geodätische Vermessung des abgeleiteten Staates'
          ]
        },
        {
          title: 'Gerichtsgutachten',
          descriptionItems: [
            'geodätische Vermessung',
            'Erkenntnisse und Meinungen machen'
          ]
        }
      ],

      contactForm: {
        title: 'Anfrage senden',
        namePlaceholder: 'Ihr Name',
        emailPlaceholder: 'Ihre E-Mail',
        numberPlaceholder: 'Ihre Telefonnummer',
        msgPlaceholder: 'Ihre Nachricht',
        sendButton: 'Senden',
        success: 'Nachricht erfolgreich gesendet!',
        failure: 'Leider gab es beim Senden der Nachricht ein Problem!'
      },

      coverSubtitle: 'geodätische Dienstleistungen und Expertise',
      locationDescription: 'FINA-Gebäude',

      copyright: 'Alle Rechte vorbehalten.'
    },
  },
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: 'hr',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;